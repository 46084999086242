import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './styles/App.scss';
import Footer from './components/Footer';
import EarlyAccessPage from './pages/EarlyAccess';

/*const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/support",
    element: <SupportPage />
  },
  {
    path: "/contact",
    element: <ContactPage />
  },
  {
    path: "/privacy",
    element: <PrivacyPolicyPage />
  },
  {
    path: "/earlyaccess",
    element: <EarlyAccessPage />
  },
]);*/

const earlyaccessRouter = createBrowserRouter([
  {
    path: "/",
    element: <EarlyAccessPage />
  }
]);

function App() {
  return (
    <div className="App">
      {/* <GeneralNavbar /> */}

      <div className='page'>
        <RouterProvider router={earlyaccessRouter} />
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
